<template>
  <div class="w-full">
    <a-descriptions :column="2" v-for="property in properties" :key="property.id" bordered style="margin-top: 1%">
      <slot></slot>
      <a-descriptions-item label="属性名称">
        <span>{{ property.name }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="是否父节点">
        <span>{{
          $constRepository.property.FILTER_NODES.find((item) => item.value == property.propertyType).label
        }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="属性标识符">
        <span>{{ property.identifier }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="属性定义唯一标识">
        <span>{{ property.encode }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="属性类型">
        <span>{{ propertyType(property) }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="属性读写权限">
        <div v-if="property.accessMode === $constRepository.property.accessMode.readOnly">只读</div>
        <div v-else-if="property.accessMode === $constRepository.property.accessMode.writeOnly">只写</div>
        <div v-else-if="property.accessMode === $constRepository.property.accessMode.readWrite">可读写</div>
        <div v-else>未知</div>
      </a-descriptions-item>
      <a-descriptions-item label="编辑方式">
        <span>{{ propertyEditMode(property) }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="默认值">
        <span>{{ property.type.defaultValue || '无' }}</span>
      </a-descriptions-item>
      <template v-if="property.type.editMode == $constRepository.productFormItem.editMode.input">
        <a-descriptions-item label="范围">
          <span v-if="property.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL">{{
            `${property.type.range.min}-${property.type.range.max}`
          }}</span>
          <span v-else>无</span>
        </a-descriptions-item>
      </template>
      <template v-if="property.type.editMode == $constRepository.productFormItem.editMode.select">
        <a-descriptions-item label="选项">
          <p v-for="(option, index) in property.type.options" :key="index">
            {{ `${option.value}:${option.description.defaultMessage}` }}
          </p>
        </a-descriptions-item>
      </template>
    </a-descriptions>
  </div>
</template>

<script>
export default {
  name: 'LoadedDevicePropertyCard',
  props: ['properties', 'propMetaData'],
  data() {
    return {
      metaData: {},
    };
  },
  methods: {
    async getMetaData() {
      if (this.propMetaData) {
        this.metaData = this.propMetaData;
      } else {
        const response = await this.$apiManager.productMetaData.getMetaData();
        this.metaData = response.data;
      }
    },
  },
  async mounted() {
    await this.getMetaData();
  },
  computed: {
    propertyType() {
      return (property) => {
        const propertyTypeName = this.metaData.dataPrimitiveTypes?.find((type) => type.value === property.type.type);
        return propertyTypeName ? propertyTypeName.name : '';
      };
    },
    propertyEditMode() {
      return (property) => {
        const modeName = this.$constRepository.productFormItem.editModeText[property.type.editMode];
        return modeName || '';
      };
    },
  },
};
</script>

<style lang="less" scoped>
.w-1\/3 {
  width: 33.33333333%;
}
.w-1\/2 {
  width: 50%;
}
.w-full {
  width: 100%;
}
</style>
