<template>
  <div class="model-product-detail" style="padding-top: 30px; padding-bottom: 30px">
    <a-card title="产品详情" style="width: 80%; margin: 2% auto">
      <template slot="extra">
        <a-select
          v-model="languageSelect"
          allowClear
          placeholder="请选择翻译语言"
          showSearch
          optionFilterProp="children"
          @change="handleLanguage"
          :style="{ width: '8vw' }"
        >
          <a-select-option v-for="(language, key) in languageOptions" :key="key" :value="language.value">{{
            language.label
          }}</a-select-option>
        </a-select>
      </template>
      <a-descriptions title="产品基础数据" bordered>
        <a-descriptions-item label="产品ID"> {{ form.productId }} </a-descriptions-item>
        <a-descriptions-item label="产品名称"> {{ form.name }} </a-descriptions-item>
        <a-descriptions-item label="产品类型">
          <a-select v-model="form.type" :disabled="true">
            <a-select-option :value="$constRepository.product.PRODUCT_TYPE_OPTIONS.root"> 父设备</a-select-option>
            <a-select-option :value="$constRepository.product.PRODUCT_TYPE_OPTIONS.child"> 子设备</a-select-option>
            <a-select-option :value="$constRepository.product.PRODUCT_TYPE_OPTIONS.thirdParty">
              第三方设备</a-select-option
            >
          </a-select>
        </a-descriptions-item>
        <a-descriptions-item label="中控内部驱动标识符">{{ form.driver }}</a-descriptions-item>
        <a-descriptions-item label="产品控制协议类型">
          <a-select v-model="form.protocol" :disabled="true">
            <a-select-option v-for="(filter, key) in filterOptions" :key="key" :value="filter.value">{{
              filter.label
            }}</a-select-option>
          </a-select></a-descriptions-item
        >
        <a-descriptions-item label="产品图标">
          <div class="clearfix">
            <a-upload list-type="picture-card" accept="image/*" :file-list="iconImage" @preview="handlePreview">
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="用户是否可见"
          ><a-switch
            :disabled="true"
            v-model="form.userVisible"
            checked-children="是"
            un-checked-children="否"
            default-checked
        /></a-descriptions-item>
        <a-descriptions-item label="强电设备"
          ><a-switch
            :disabled="true"
            v-model="form.metadata.strongCurrent"
            checked-children="是"
            un-checked-children="否"
            default-checked
        /></a-descriptions-item>
        <a-descriptions-item label="产品使用频率">
          <template v-if="form.useFrequency">
            {{
              $constRepository.product.USE_FREQUENCY_OPTIONS.find((option) => option.value === form.useFrequency).label
            }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="发布区域">
          <span v-if="form.regions">
            {{ form.regions.map((region) => region.name).join('、') }}
          </span>
          <span v-else>-</span>
        </a-descriptions-item>
        <a-descriptions-item label="备注">
          {{ form.remark }}
        </a-descriptions-item>
      </a-descriptions>
      <template v-if="form.loadedDeviceTypes && form.loadedDeviceTypes.length > 0">
        <div class="loadDevice_title">产品负载设备类型</div>
        <a-descriptions
          class="loadDevice_description"
          v-for="loadedDeviceType in form.loadedDeviceTypes"
          :key="loadedDeviceType.loadedDeviceTypeId"
          :column="2"
          bordered
        >
          <a-descriptions-item label="负载设备类型ID">
            {{ loadedDeviceType.loadedDeviceTypeId }}
          </a-descriptions-item>
          <a-descriptions-item label="负载设备类型名称">
            {{ loadedDeviceType.name }}
          </a-descriptions-item>
        </a-descriptions>
      </template>
      <template v-if="form.properties && form.properties.length > 0">
        <div class="loadDevice_title">产品属性</div>
        <loaded-device-property-card :propMetaData="metaData" :properties="form.properties" />
      </template>
      <template v-if="form.services && form.services.length > 0">
        <div class="loadDevice_title">产品服务</div>
        <a-descriptions
          class="product_service"
          v-for="service in form.services"
          :key="service.identifier"
          :column="2"
          bordered
        >
          <a-descriptions-item label="服务标识符">
            {{ service.identifier }}
          </a-descriptions-item>
          <a-descriptions-item label="服务描述">
            {{ service.description.defaultMessage }}
          </a-descriptions-item>
          <a-descriptions-item label="超时值">
            {{ service.timeout }}
          </a-descriptions-item>
          <a-descriptions-item label="备注">
            {{ service.remark }}
          </a-descriptions-item>
        </a-descriptions>
      </template>
      <template v-if="form.configurationItems && form.configurationItems.length > 0">
        <div class="loadDevice_title">产品配置</div>
        <a-descriptions
          class="product_configuration"
          v-for="configurationItem in form.configurationItems"
          :key="configurationItem.id"
          :column="2"
          bordered
        >
          <a-descriptions-item label="配置标识符">
            {{ configurationItem.identifier }}
          </a-descriptions-item>
          <a-descriptions-item label="配置描述">
            {{ configurationItem.description.defaultMessage }}
          </a-descriptions-item>
          <a-descriptions-item label="配置类型">
            <span>{{
              metaData.dataPrimitiveTypes.find((type) => type.value == configurationItem.type.type).name
            }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="编辑方式">
            <span>{{ $constRepository.productFormItem.editModeText[configurationItem.type.editMode] }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="默认值">
            <span>{{ configurationItem.type.defaultValue || '无' }}</span>
          </a-descriptions-item>
          <template v-if="configurationItem.type.editMode == $constRepository.productFormItem.editMode.input">
            <a-descriptions-item label="范围">
              <span v-if="configurationItem.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL">{{
                `${configurationItem.type.range.min}-${configurationItem.type.range.max}`
              }}</span>
              <span v-else>无</span>
            </a-descriptions-item>
          </template>
          <template v-if="configurationItem.type.editMode == $constRepository.productFormItem.editMode.select">
            <a-descriptions-item label="选项">
              <p v-for="(option, index) in configurationItem.type.options" :key="index">
                {{ `${option.value}:${option.description.defaultMessage}` }}
              </p>
            </a-descriptions-item>
          </template>
        </a-descriptions>
      </template>
    </a-card>
    <a-card title="编辑器" style="width: 80%; margin: 0 auto; margin-bottom: 2%">
      <vue-json-editor v-model="form" :showBtns="false" :mode="'code'" lang="zh" style="height: 800px" />
      <a-button type="primary" style="margin-top: 2%" @click="$router.back()">返回</a-button>
    </a-card>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
import LoadedDevicePropertyCard from '../ai-home/loaded-device/components/loadedDevicePropertyCard';

export default {
  name: 'ProductDetail',
  components: {
    vueJsonEditor,
    LoadedDevicePropertyCard,
  },
  async mounted() {
    if (this.$route.params.id) {
      await Promise.all([this.listProductById(this.$route.params.id), this.getMetaData()]);
    }
  },
  data() {
    return {
      languageSelect: this.$constRepository.language.FILTER_OPTION_ZH_CN.value,
      languageOptions: this.$constRepository.language.FILTER_OPTIONS,
      filterOptions: this.$constRepository.product.PRODUCT_PROTOCOL_OPTIONIS,
      form: {
        metadata: {},
        regions: [],
      },
      previewVisible: false,
      previewImage: '',
      iconImage: [],
      metaData: {},
    };
  },
  methods: {
    async handlePreview(file) {
      const imageFile = file;
      if (!imageFile.url && !imageFile.preview) {
        imageFile.preview = await this.getBase64(imageFile.originFileObj);
      }
      this.previewImage = imageFile.url || imageFile.preview;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async listProductById(id) {
      const headers = {
        'Accept-Language': this.languageSelect,
        'FULL-PRODUCT': true,
      };
      const response = await this.$apiManager.publicApi.productApi.getProductById(id, headers);
      if (!response.data.regions) {
        response.data.regions = [];
      }
      this.form = response.data;
      this.initImages();
    },
    initImages() {
      if (this.form.iconDownloadUrl) {
        this.iconImage = [
          {
            url: this.form.iconDownloadUrl,
            filePath: this.form.icon,
            name: this.form.icon,
            uid: -1,
          },
        ];
      }
    },
    async handleLanguage() {
      await this.listProductById(this.$route.params.id);
    },
    cancel() {
      this.$router.back();
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.jsoneditor-poweredBy {
  display: none;
}
// /deep/.jsoneditor-outer {
//   height: 25vw;
// }
/deep/ .jsoneditor-vue {
  height: 100%;
}
.green-btn {
  color: green;
  border: none;
}
.loadDevice_title {
  margin-top: 4%;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
.product_configuration {
  margin-top: 1%;
}

.product_service {
  margin-top: 1%;
}
.model-product-detail {
  /deep/ .ant-descriptions-row {
    width: 100%;
    word-break: break-all;
    .ant-descriptions-item-label {
      width: 170px;
    }
    .ant-descriptions-item-content {
      width:200px;
    }
  }
}
</style>
